// src/components/Product.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../customColors.css'
import detectAttack from '../content/RecordedAnomalyDemo.mp4';
import networkStatus from '../content/ShowingNetworkStatus.mp4';
import addDevice from '../content/AddDevice.mp4';
function Services() {
  return (
    <div>
      {/* Product Hero Section */}
      <section className="text-center py-5 bg-custom-dark">
        <div className="container">
          <h1 className="display-4"><strong>Acra AI Hotspot</strong></h1>
          <p>Machine Learning IoT Hotspot for Intrusion Detection and Prevention.</p>
        </div>
      </section>

      {/* Product Features Section */}
      <section className="bg-custom-light">
      <div className = "container py-5">
        <h2 className="text-center mb-4"><strong>Key Features</strong></h2>
        <div className="row">
          <div className="col-md-4">
            <h5><strong>Wireless Access Point (WAP)</strong></h5>
            <p>Wireless Access Point that protects the devices connected to it. All you have to do is connect your smart device to the Access Point.</p>
          </div>
          <div className="col-md-4">
            <h5><strong>Secure</strong></h5>
            <p>Uses P2P to protect your data. This will keep your network traffic between your app and the Access Point.</p>
          </div>
          <div className="col-md-4">
            <h5><strong>Powerful Machine Learning on the Edge</strong></h5>
            <p>Built on well researched algorithms for detecting Botnet, SSDP, MITM, DDoS Attacks and more.</p>
          </div>
        </div>
       </div>
      </section>

      {/* How it Works */}
       <section className="bg-custom-dark py-5">
	   <div className="container">
	      <div className="row">
	        {/* Left Column: Title/Heading */}
	        <div className="col-md-4 d-flex align-items-center">
	          <h2 className="font-weight-bold">
		    <strong>How it Works</strong>
	          </h2>
	        </div>

	        {/* Right Column: Text Block */}
	        <div className="col-md-8">
	          <p>
	            When you connect your smart devices to the WAP, the WAP will monitor your traffic on the device itself (No Cloud Communication). The WAP will use machine learning to learn that devices behavior. In the event that someone is trying to infiltrate that device, The WAP will be able to pickup the abnormal traffic and notify the app in real time. We will let the user know as soon as something happens and options to stop it.
	          </p>
	        </div>
	      </div>
	     </div>
        </section>
        
    {/*App Snippets*/}
      <section className="bg-custom-light">
      <div className = "container py-5">
      {/* Header */}
      <h2 className="text-center"><strong>App Snippets</strong></h2>

      {/* Horizontal List */}
      <div className="row text-center">
        {/* First Block */}
        <div className="col-md-4">
        
        <p>Adding the WAP</p>
        
	<video width="300" controls>
	  <source src={addDevice} type="video/mp4" />
	  Your browser does not support the video tag.
	</video>

        </div>

        {/* Second Block */}
        <div className="col-md-4">
        
        <p>Showing the Network Fingerprint</p>
        
	<video width="300" controls>
	  <source src={networkStatus} type="video/mp4" />
	  Your browser does not support the video tag.
	</video>

        </div>

        {/* Third Block */}
        <div className="col-md-4">
        
        <p>Detecting Mirai Attack (Normal Traffic until 20sec.)</p>
        
	<video width="300" controls>
	  <source src={detectAttack} type="video/mp4" />
	  Your browser does not support the video tag.
	</video>
	
        </div>
      </div>
      </div>
    </section>
        
      {/* Call to Action Section */}
      <section className="bg-custom-dark text-center py-5">
        <h2 className="h3"><strong>Want to Schedule A Live Demo?</strong></h2>
        <a href="/contact" className="btn btn-custom btn-lg">Reach Out!</a>
      </section>

    </div>
  );
}

export default Services;

