// src/components/Contact.js
import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../customColors.css';
import emailjs from 'emailjs-com';

function Contact() {
 const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    // EmailJS configuration
    const SERVICE_ID = 'service_m7nmybi';
    const TEMPLATE_ID = 'template_6fjc63y';
    const PUBLIC_KEY = '94dgRNcaXmAMugDBb';

    const templateParams = {
      name,
      email,
      message,
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY,
      )
          .then((result) => {
            console.log(result.text);
            alert('Message Sent Successfully')
          }, (error) => {
            console.log(error.text);
            alert('Something went wrong!')
          });
        event.target.reset()

    // Optionally reset the form
    setName('');
    setEmail('');
    setMessage('');
  };
  return (
    <div>
      {/* Contact Form Section */}
      <section className="py-5 bg-custom-dark">
      <div className = "container">
      <h1 className="text-center mb-4"><strong>Get in Touch</strong></h1>
      <p className="text-center">We'd love to hear from you! Fill out the form below to reach out.</p>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Your Name"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your Email"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                rows="5"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Your Message"
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-custom btn-block">
              Submit
            </button>
          </form>
        </div>
      </div>
      </div>
    </section>
      {/* Contact Details Section */}
      <section className="bg-custom-light text-center py-5">
          <h3><strong>Other Ways to Contact Us</strong></h3>
          <p>Email: sarroyo@acraai.org | Phone: (562) 275-8865</p>
          {/*<p>Follow us on social media: 
            <a href="https://facebook.com" className="mx-2">Facebook</a> 
            <a href="https://twitter.com" className="mx-2">Twitter</a>
          </p>*/}
      </section>
      <section className = "bg-custom-light py-5"/>
      <section className = "bg-custom-light py-5"/>
    </div>
  );
}

export default Contact;

