// src/components/About.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../customColors.css'
import headshot from '../content/Headshot.jpg';
function About() {
  return (
    <div className="">
      <header className="bg-custom-dark text-center py-5">
        <h1 className="display-4"><strong>About Us</strong></h1>
        <p className="lead">We are passionate about creating excellent products and services that improve people's lives.</p>
      </header>

	{/*Vision Overview*/}
	 <section className="bg-custom-light py-5">
	   <div className="container">
	      <div className="row">
	      
	      	{/* Left Column: Title/Heading */}
	        <div className="col-md-4 d-flex align-items-center">
	          <h2>
	          <strong>
	            Our Vision
	           </strong>
	          </h2>
	        </div>

	          {/* Right Column: Vertical List of Vision Statments */}
		<div className="col-md-8">
		  <ul className="list-unstyled">
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
		      Our vision is to be a leader in IoT cybersecurity, empowering smart device users with seamless and secure user experiences.
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
                      We aim to revolutionize the industry with cutting-edge machine learning technologies that proactively defend against evolving cyber threats on the device
                       itself. 
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
			Through partnership with innovative tech companies, we will set new standards in data privacy and security. 
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
			Our ultimate goal is to create a world where every connected device is protected, giving users peace of mind in an increasingly digital age.
		    </li>
		    <li className="mb-3">
		      <i className="fas fa-check-circle text-success mr-2"></i>
		       By setting standards to prevent data from being centralized, We will protect not just your network but your data from being sold.
		    </li>
		  </ul>
		</div>
		

	      </div>
	     </div>
        </section>
      {/*About our Founder*/}
      <section className=" bg-custom-dark">
      <div className="container py-5">
      <div className="row align-items-center">
        {/* Left Column: Blurb about Founder */}
        <div className="col-md-6">
          <h2><strong>About Our Founder</strong></h2>
          <p>
           Our Founder, Steven Arroyo, created Acra AI in September of 2023,while
	writing his thesis at the time. During one of his undergraduate courses in 2021,
	he discovered an anomaly detection algorithm (ensemble of autoencoders) that
	had excellent results detecting Botnet attacks on a single device. His professor
	Dr. Shen-Shyang Ho worked with and encouraged Steven to write a publication
	to IEEE Conference on Machine Learning Applications (ICMLA) 2022 on his
	findings. Steven then took this fundamental algorithm and created an entire
	federated learning system to detect many cybersecurity attacks on IoT devices.
	This became his publication in IEEE Conference on Fog and Edge Computing
	(ICFEC) 2024 along with his masters Thesis work and journal submission to
	Science Directs Expert Systems and Application (ESA).
          </p>
          <p>
          	While working on his academic research, he was also improving the fundamental algorithm to significantly increase the computational efficiency without
	changing the underlying machine learning. With many months of trial and error, he succeeded in getting the algorithm to run blistering fast on a small 6.2cm
	by 3.3cm micro-controller with 1GHz of CPU and 512 MB of RAM. This microcontroller is capable of learning an entire homes IoT device traffic to detect
	anomalies and protect those devices. This breakthrough has become Product 1
	for Acra AI. Product 2 and 3 will build upon Product 1 to systematically cover
	the entire market of individuals who buy smart devices.
          </p>
          <p>
	Outside of Academia, Steven has been programming for almost a decade. He worked as a Software Engineer and Researcher for 3 years creating ML Internal Research and Development (IRAD) for Senior Leadership. He also worked on legacy Ballistic Missile Defense (BMD) Command and Control Systems emphasizing real time reliability and efficency.
          </p>
        </div>

        {/* Right Column: Image of Founder */}
        <div className="col-md-6 text-center">
 	<img
	     src={headshot}
             className="img-fluid mb-3"
	     alt="Headshot"
             style={{ width: '450px', height: 'auto' }}
         />
        </div>
      </div>
      </div>
    </section>
      {/* Call to Action */}
      <section className="bg-custom-light text-center py-5">
        <h4><strong>Want to collaborate?</strong></h4>
        <a href="/contact" className="btn btn-custom btn-lg">Contact Us</a>
      </section>
      
    </div>
  );
}

export default About;
