// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../customColors.css';
import logo from '../content/Logo.png'

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-custom-dark">
      <div className="container-fluid">
        <Link className="navbar-brand bg-custom-label" to="/">
          <img src={logo}alt="Logo" width="50" />
        </Link>
        <button className="navbar-toggler bg-custom-burg" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon bg-custom-burg"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link bg-custom-dark bg-custom-label" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link bg-custom-dark bg-custom-label" to="/about">About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link bg-custom-dark bg-custom-label" to="/services">Services</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link bg-custom-dark bg-custom-label" to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
